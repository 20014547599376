"use strict";

let msDetected = false;

class PqPasteHelper {

    /**
     *
     * @param container
     * @param delta {number} | {null}
     * @fixme: default font size should be a parameter to make this function independent and reusable
     */
    static convertFontSizeFromPxToVw(container, delta) {
        let defaultFontSizeVw = 2;

        let setDefault = function() {
            if (!container.style) {
                return;
            }

            container.style.fontSize = defaultFontSizeVw + 'vw';
            container.setAttribute('data-base-font-size', defaultFontSizeVw + 'vw');
            container.style.fontFamily = 'Arial';
        };

        if (msDetected || (container.classList && container.classList.contains('MsoNormal'))) {
            if (!msDetected) {
                msDetected = true;
            }

            setDefault();
        }
        else if (container.style) {
            if (container.style.fontSize) {
                let fontSizeVw = PqPasteHelper.getDefaultFontSize('vw');

                if (container.style.fontSize.indexOf('px') !== -1 || container.style.fontSize.indexOf('pt') !== -1) {
                    fontSizeVw = PqPasteHelper.convertPxToVw(container.style.fontSize);
                    console.log('Px to VW = ' + fontSizeVw);
                }
                else if (container.style.fontSize.indexOf('em') !== -1) {
                    fontSizeVw = PqPasteHelper.convertEmToVw(container.style.fontSize);
                }

                if (delta) {
                    fontSizeVw = fontSizeVw * delta;
                }

                fontSizeVw = +(fontSizeVw.toFixed(2));

                container.style.fontSize = fontSizeVw  + 'vw';
                container.setAttribute('data-base-font-size', fontSizeVw + 'vw');
            }
            else {
                setDefault();
            }
        }

        for (let i = 0; i < container.children.length; i++) {
            let el = container.children[i];
            PqPasteHelper.convertFontSizeFromPxToVw(el, delta);
        }
    };

    static adjustFontSizeBy(container, scaleFactor) {
        console.log('SCALE: ' + scaleFactor);
        if (!container.hasChildNodes()) {
            return;
        }

        if (container.style && container.style.fontSize && container.style.fontSize.indexOf('vw') !== -1) {
            let curFontSize = parseFloat(container.getAttribute('data-base-font-size'));
            let newFontSize = curFontSize * parseFloat(scaleFactor);

            container.style.fontSize = newFontSize + 'vw';
        }

        for (let i = 0; i < container.childNodes.length; i++) {
            let node = container.childNodes[i];

            // if (node.style && node.style.fontSize && node.style.fontSize.indexOf('vw') !== -1) {
            //     let curFontSize = parseFloat(node.getAttribute('data-base-font-size'));
            //     let newFontSize = curFontSize * parseFloat(scaleFactor);
            //
            //     node.style.fontSize = newFontSize + 'vw';
            // }

            PqPasteHelper.adjustFontSizeBy(node, scaleFactor);

            // if (node.hasChildNodes()) {
            //     if (node.style && node.style.fontSize && node.style.fontSize.indexOf('vw') !== -1) {
            //         let curFontSize = parseFloat(node.getAttribute('data-base-font-size'));
            //         let newFontSize = curFontSize * parseFloat(scaleFactor);
            //
            //         node.style.fontSize = newFontSize + 'vw';
            //     }
            //
            //     PqPasteHelper.adjustFontSizeBy(node, scaleFactor);
            // }
        }
    }

    /**
     * converts pixels to vw
     * @param pixels
     * @returns {number} | {null}
     */
    static convertPxToVw(pixels) {
        return parseFloat(pixels) * (100 / document.documentElement.clientWidth);
    }

    static convertEmToVw(em) {
        // let emScaleFactor = PqPasteHelper.getDefaultFontSize();
        let emScaleFactor = 16;
        return PqPasteHelper.convertPxToVw(parseFloat(em) * emScaleFactor);
    }

    static computeDeltaBaseFontSize(container) {
        if (!container.hasChildNodes()) {
            return;
        }

        let fontSizes = new Map();

        let traverse = function(container) {
            for (let i = 0; i < container.childNodes.length; i++) {
                let node = container.childNodes[i];

                if (node.style && node.style.fontSize) {
                    let fontSize = node.style.fontSize;

                    if (node.style.fontSize.indexOf('px') !== -1) {
                        fontSize = +(PqPasteHelper.convertPxToVw(fontSize).toFixed(2));
                    }
                    else if (node.style.fontSize.indexOf('em') !== -1) {
                        fontSize = +(PqPasteHelper.convertEmToVw(fontSize).toFixed(2));
                        console.log('FONT SIZE GENERATED: ' + fontSize);
                    }
                    else {
                        fontSize = +(PqPasteHelper.getDefaultFontSize('vw').toFixed(2));
                    }

                    if (fontSizes.has(fontSize)) {
                        fontSizes.set(fontSize, fontSizes.get(fontSize) + 1);
                    } else {
                        fontSizes.set(fontSize, 1);
                    }
                }

                if (node.hasChildNodes()) {
                    traverse(node);
                }
            }
        };

        traverse(container);

        // average font sizes
        let mostCommonFontSize = 0;
        fontSizes.forEach(function(key, value) {
            if (value > mostCommonFontSize) {
                mostCommonFontSize = value;
            }
        });

        console.log(fontSizes);
        console.log('Most common font size: ' + mostCommonFontSize);

        if (mostCommonFontSize) {
            let defaultFontSize = PqPasteHelper.getDefaultFontSize('vw');
            return +((defaultFontSize / mostCommonFontSize).toFixed(2));
        }

        return null;
    }

    /**
     * retrieves the default font size in pixels
     * @returns {number}
     */
    static getDefaultFontSize(format) {
        format = format || 'px';

        let style = window.getComputedStyle(document.body);

        if (format === 'vw' && style.getPropertyValue('font-size').indexOf('vw') === -1) {
            console.log('Trying to get vw: ' + PqPasteHelper.convertPxToVw(style.getPropertyValue('font-size')));
            return PqPasteHelper.convertPxToVw(style.getPropertyValue('font-size'));
        }

        return parseFloat(style.getPropertyValue('font-size'));
    }

}

class  ContentManager {
    constructor() {
        this.printButton = document.getElementById('pq_paste_print_btn');
        this.pdfButton = document.getElementById('pq_paste_pdf_btn');
        this.inputWindow = document.getElementById('pq_paste_text_slot');
        this.selectedPcues = [];

        this.headerUserName = document.getElementById('pq_paste_user_name');
        this.headerTitle = document.getElementById('pq_paste_title');
        this.headerNote =  document.getElementById('pq_paste_note');
        this.headerInclude = document.getElementById('pq_paste_include_header');
        this.headerImageHeight =  document.getElementById('header_image_height');
        this.headerNameFontSize = document.getElementById('header_name_font_size');
        this.headerTitleFontSize =  document.getElementById('header_title_font_size');

        this.listenToEvents();
    }

    downloadPdf() {
        console.log('download');
        let pqForm = document.getElementById('pq_paste_form');
        let xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://mlc.learningstewards.org/wp-admin/admin-ajax.php');
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function() {
            pqForm.submit();

            console.log('monitorDownload');
            let monitorDownload = setInterval(function(){
                let xhr = new XMLHttpRequest();
                xhr.open('POST', 'https://mlc.learningstewards.org/wp-admin/admin-ajax.php');
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                xhr.onload = function() {
                    console.log(xhr.responseText);
                    if(xhr.responseText === 'true') {
                        console.log('clear');
                        document.getElementById('print_pdf_dialog').style.display = "block";
                        document.getElementById('pdf_loading_dialog').style.display = "none";
                        clearInterval(monitorDownload);
                    }
                };

                xhr.send(encodeURI('action=pdf_ready'));
            }, 1000);
        };
        xhr.send(encodeURI('action=reset_pdf'));
    }

    printOutput(printWindow) {
        // let pcues = this.selectedPcues.join();
        let imageHeight = this.headerImageHeight.value;
        let nameFontSize = this.headerNameFontSize.value;
        let titleFontSize = this.headerTitleFontSize.value;
        let name = this.headerUserName.value;
        let title = this.headerTitle.value;
        let note = this.headerNote.value;
        let header = this.headerInclude.checked ? 1 : 0;

        printWindow.document.write("<div class='loading' style='font-family: Tahoma, Geneva, Sans-serif; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);'><span style='font-size: 3vw; color: #a7a7a7'>Processing...</span></div>");
        printWindow.moveTo(0,0);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://mlc.learningstewards.org/wp-admin/admin-ajax.php');
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function() {
            printWindow.document.write( xhr.responseText); console.log(xhr.responseText);

            setTimeout(function(){
                printWindow.focus();
                printWindow.print();
                printWindow.close();
            }, 1500);
        };

        let data = 'header_include='+header;
        data += '&user_name='+name;
        data += '&title='+title;
        data += '&note='+note;
        data += '&name_font_size='+nameFontSize;
        data += '&image_height='+imageHeight;
        data += '&screen_width='+document.documentElement.clientWidth;
        data += '&action=pcue_filter';
        data += '&content='+escape(this.inputWindow.innerHTML);
        data += '&pcues='+this.selectedPcues.join();

        xhr.send(encodeURI(data));
        console.log(data);
    }

    scaleOutputHeader(outputType) {
        let name =  this.headerUserName.value;
        let title = this.headerTitle.value;
        let note =  this.headerNote.value;

        //scale header
        let html = '<!DOCTYPE html>';
        html += '<html><head>';
        html += '<script src="https://ajax.googleapis.com/ajax/libs/jquery/1/jquery.min.js"></script>';
        html += '<script src="https://jquery-textfill.github.io/js/textfill/jquery.textfill.js"></script>';
        html += '<style>';
        html += 'html {width: 8.5in; height: 11in}';
        html += 'body { font-family: Tahoma, Geneva, Sans-serif; } ';
        html += '</style>';
        html += '</head>';
        html += '<body style="width: 8.5in; height: 11in ">';
        html += '<div  style="postion: relative" >';
        html += '<div  style="position: absolute; left: 0; top: 0;">';
        html += '<img style="width: width: 1.53in; height: 40px" id="header_image" src="https://mlc.learningstewards.org/wp-content/uploads/2017/12/copypastePQ.jpg."></div>';
        html += '<div id="header_title" style="height: 30px; text-align: center; display: block; width: 4.25in; margin: 0 auto;"><span>'+title+'</span></div>';
        html += '<div id="header_name" style="height: 25px; border: 1px solid black; width: 1.5in; position: absolute; right: 0; top: 0; text-align: right">';
        html += '<span>'+name+'</span><br><span></div>';
        html += '</div>';
        html += '<div id="header_note" style="color: red; margin: 30px 0 40px ">'+note+'</div>';
        html += '</body>';
        html += '</html>';
        html += '<script>';
        //html += 'jQuery("#header_name").fitText(1.2); jQuery("#header_title").fitText();';
        html += '$("#header_name, #header_title").textfill({ success: function() {  ';
        html += 'console.log("yay!"); ';
        html += '},fail: function() { console.log("boo hoo!")} });';
        html += 'let nameFontSize = jQuery("#header_name > span").css("font-size");  jQuery("#header_note").css("font-size", nameFontSize );';
        html += 'let titleFontSize = jQuery("#header_title > span").css("font-size"); ';
        html += 'let imageHeight = jQuery("#header_name > span").height();  jQuery("#header_image").height(imageHeight); ';
        html += 'parent.postMessage({"scaleHeader": true , "outputType": "'+outputType+'", "imageHeight": imageHeight, "nameFontSize": nameFontSize, "titleFontSize": titleFontSize }, "*")';

        //html += 'parent.postMessage({"scaleHeader": true , "nameFontSize": nameFontSize, "titleFontSize": titleFontSize }, "*")';
        html += '</script>';

        let iframe = document.createElement("iframe");
        iframe.setAttribute('id', 'output_iframe');
        iframe.src = 'data:text/html;charset=utf-8,' + encodeURI(html);
        iframe.style.width = "8.5in";
        iframe.style.position = "absolute";
        iframe.style.left = "-100%";

        document.body.appendChild(iframe);
    }


    disablePrintPdfButtons() {
        this.printButton.style.opacity = '0.5';
        this.printButton.style.pointerEvents = 'none';
        this.pdfButton.style.opacity = '0.5';
        this.pdfButton.style.pointerEvents = 'none';
    }

    enablePrintPdfButtons() {
        this.printButton.style.opacity = '1';
        this.printButton.style.pointerEvents = 'auto';
        this.pdfButton.style.opacity = '1';
        this.pdfButton.style.pointerEvents = 'auto';
    }

    listenToEvents() {
        let self = this;

        let processPrintButton = document.getElementById('pq_paste_process_print');
        let processPdfButton = document.getElementById('pq_paste_process_pdf');
        let pqContent = document.getElementById('pq_paste_content');

        let showHeader = document.getElementById('show_print_pdf_header');
        let lessHeader = document.getElementById('less_print_pdf_header');

        let printPdfModal = document.getElementById('print_pdf_dialog');
        let printPdfRightRegion = document.getElementById('print_pdf_right_region');
        let modalContent = printPdfModal.getElementsByClassName("modal-content")[0];
        let pdfLoadingModal = document.getElementById('pdf_loading_dialog');
        let pdfLoading = false;

        let pqNoneCheckBox = document.getElementById('pq_none');
        let pqAllCheckBox = document.getElementById('pq_all');
        let pqCheckBoxes = document.querySelectorAll("input[name^='pq[']");

        self.disablePrintPdfButtons();

        self.headerUserName.value  = localStorage.getItem('pq_paste_user_name');
        self.headerTitle.value 	   = localStorage.getItem('pq_paste_title');
        self.headerNote.value 	   = localStorage.getItem('pq_paste_note');
        self.headerInclude.checked = localStorage.getItem('header_include') === 'true' ? true : false;
        self.selectedPcues 		   = localStorage.getItem('selected_pcues') ? localStorage.getItem('selected_pcues').split(',') : [];

        console.log(self.selectedPcues );
        pqNoneCheckBox.checked = true;
        if(self.selectedPcues.length > 0) {
            pqNoneCheckBox.checked = false;

            for (let i = 0; i < pqCheckBoxes.length; i++){
                if( self.selectedPcues.indexOf(pqCheckBoxes[i].value) > -1 ) {
                    pqCheckBoxes[i].checked = true;
                }
            }

            if (self.selectedPcues.length == 8) {
                pqAllCheckBox.checked = true;
            }
        }

        showHeader.addEventListener('click', function(event) {
            printPdfRightRegion.style.display = "block";
            lessHeader.style.display = "block";
            showHeader.style.display = "none";
            modalContent.style.width = "730px";
        });

        lessHeader.addEventListener('click', function(event) {
            printPdfRightRegion.style.display = "none";
            lessHeader.style.display = "none";
            showHeader.style.display = "block";
            modalContent.style.width = "320px";
        });

        self.printButton.addEventListener('click', function(event) {
            processPrintButton.style.display = "block";
        });

        self.pdfButton.addEventListener('click', function(event) {
            processPdfButton.style.display = "block";
        });

        self.headerUserName.addEventListener('change', function(event) {
            localStorage.setItem('pq_paste_user_name', self.headerUserName.value);
        });

        self.headerTitle.addEventListener('change', function(event) {
            localStorage.setItem('pq_paste_title', self.headerTitle.value);
        });

        self.headerNote.addEventListener('change', function(event) {
            localStorage.setItem('pq_paste_note', self.headerNote.value);
        });

        self.headerInclude.addEventListener('click', function(event) {
            localStorage.setItem('header_include', self.headerInclude.checked ? true : false);
        });

        /* window.addEventListener("focus", function(event) {
             if (pdfLoading) {
                 printPdfModal.style.display = "block";
                 pdfLoadingModal.style.display = "none";
                 pdfLoading = false;
             }
         });*/

        window.addEventListener('click', function(event) {
            // When the user clicks anywhere outside of the modal, close it
            if (event.target === printPdfModal) {
                printPdfModal.style.display = "none";
                processPrintButton.style.display = "none";
                processPdfButton.style.display = "none";
            }

            //show print/PDF modal
            if (event.target.className && event.target.className.indexOf('pq_paste_print_pdf_btn') !== -1 ) {
                printPdfModal.style.display = "block";
            }
        });

        printPdfModal.addEventListener('click', function(event) {
            let printWindow = null;
            let outputScreenWidth = document.getElementById('pq_paste_screen_width');
            outputScreenWidth.value = document.documentElement.clientWidth;

            pqContent.value = self.inputWindow.innerHTML;

            //handles checkbox clicks
            if (pqAllCheckBox === event.target) {
                self.selectedPcues = [];

                for (let i = 0; i < pqCheckBoxes.length; i++){
                    if (pqCheckBoxes[i] !== event.target) {
                        pqCheckBoxes[i].checked = pqAllCheckBox.checked;
                    }

                    if(pqCheckBoxes[i].checked && pqCheckBoxes[i].value !== 'on') {
                        self.selectedPcues.push(pqCheckBoxes[i].value);
                    }
                }

                pqNoneCheckBox.checked = !pqAllCheckBox.checked;
                localStorage.setItem('selected_pcues', self.selectedPcues);
            }
            else if (pqNoneCheckBox === event.target) {
                self.selectedPcues = [];
                for (let i = 0; i < pqCheckBoxes.length; i++){
                    if (pqCheckBoxes[i] !== event.target) {
                        pqCheckBoxes[i].checked = !pqNoneCheckBox.checked;
                        if(!pqNoneCheckBox.checked && pqCheckBoxes[i].checked && pqCheckBoxes[i].value !== 'on') {
                            self.selectedPcues.push(pqCheckBoxes[i].value);
                        }
                    }
                }
                localStorage.setItem('selected_pcues', self.selectedPcues);
                pqAllCheckBox.checked = false;
            }
            else {
                self.selectedPcues = [];
                for (let i = 0; i < pqCheckBoxes.length; i++){
                    if (pqCheckBoxes[i] === event.target) {
                        pqNoneCheckBox.checked = false;
                    }

                    if(pqCheckBoxes[i].checked && pqCheckBoxes[i].value !== 'on') {
                        self.selectedPcues.push(pqCheckBoxes[i].value);
                    }
                }
                localStorage.setItem('selected_pcues', self.selectedPcues);
            }

            if (self.selectedPcues.length == 8) {
                pqAllCheckBox.checked = true;
            }

            window.addEventListener('message', function(event) {
                if(event.data.scaleHeader) {
                    self.headerImageHeight.value = event.data.imageHeight;
                    self.headerNameFontSize.value = event.data.nameFontSize;
                    self.headerTitleFontSize.value = event.data.titleFontSize;

                    if(event.data.outputType === 'pdf') {
                        self.downloadPdf();
                    } else if (event.data.outputType === 'print') {
                        self.printOutput(printWindow);
                    }
                }
            });


            //download PDF
            if (processPdfButton === event.target) {
                // pdfLoading = true;
                printPdfModal.style.display = "none";
                pdfLoadingModal.style.display = "block";

                if(self.headerInclude.checked) {
                    self.scaleOutputHeader('pdf');
                } else {
                    self.downloadPdf();
                }

            }

            //print
            if (processPrintButton === event.target) {
                let params = [
                    'height='+screen.height,
                    'width='+screen.width,
                    'fullscreen=yes' // only works in IE, but here for completeness
                ].join(',');

                printWindow = window.open("https://mlc.learningstewards.org/pq-paste-output", "popup_window", params);
                printWindow.document.body.innerHTML = '';

                if(self.headerInclude.checked) {
                    self.scaleOutputHeader('print');
                } else {
                    self.printOutput(printWindow);
                }
            }
        });
    }

}

class PqPasteController {

    constructor() {
        this.textScaleFactors = [1.0, 1.2, 1.4, 1.6, 1.8, 2.0];
        this.curScaleFactorPointer = 0;
        this.defaultFontSize = null;
    }

    run() {
        if (this.validate()) {
            this.init();
            this.listenToEvents();
        }
        else {
            console.log('ERROR: PQ Paste Component not found.');
        }
    }

    init() {
        console.log('Initiating PQ Paste Module');
        this.defaultFontSize = PqPasteHelper.getDefaultFontSize('vw');

        // adjust max width of pq paste slot
        let inputWindow = document.getElementById('pq_paste_text_slot');
        let style = window.getComputedStyle(inputWindow);

        inputWindow.style.maxWidth = PqPasteHelper.convertPxToVw(style.getPropertyValue('width')).toFixed(2) + 'vw';
    }

    validate() {
        if (document.getElementById('pq_paste_component')) {
            return true;
        }

        return false;
    }

    listenToEvents() {
        let self = this;

        let processButton = document.getElementById('pq_paste_process_btn');
        let resetButton = document.getElementById('pq_paste_reset_btn');
        let inputWindow = document.getElementById('pq_paste_text_slot');

        let contentManager = new ContentManager();

        //use DOMParser to support iPad2
        let domParser = new DOMParser();

        // let fullScreenButton = document.getElementById('pq_paste_fullscreen');
        //
        // fullScreenButton.addEventListener('click', function(event) {
        //     if (
        //         document.fullscreenElement ||
        //         document.webkitFullscreenElement ||
        //         document.mozFullScreenElement ||
        //         document.msFullscreenElement
        //     ) {
        //         if (document.exitFullscreen) {
        //             document.exitFullscreen();
        //         } else if (document.mozCancelFullScreen) {
        //             document.mozCancelFullScreen();
        //         } else if (document.webkitExitFullscreen) {
        //             document.webkitExitFullscreen();
        //         } else if (document.msExitFullscreen) {
        //             document.msExitFullscreen();
        //         }
        //     } else {
        //         let element = document.getElementById('pq_paste_component');
        //
        //         if (element.requestFullscreen) {
        //             element.requestFullscreen();
        //         } else if (element.mozRequestFullScreen) {
        //             element.mozRequestFullScreen();
        //         } else if (element.webkitRequestFullscreen) {
        //             element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        //         } else if (element.msRequestFullscreen) {
        //             element.msRequestFullscreen();
        //         }
        //     }
        //
        // });



        let placeHolder = '<span style="color:red;font-size:2.5vw">Paste or Type text here</span>';

        inputWindow.addEventListener('focus', function(event) {
            contentManager.enablePrintPdfButtons();
            if (inputWindow.innerHTML === placeHolder) {
                inputWindow.innerHTML = '';
            }
        });

        inputWindow.addEventListener('blur', function(event) {
            if (inputWindow.innerHTML === '') {
                inputWindow.innerHTML = placeHolder;
                contentManager.disablePrintPdfButtons();
            }
        });

        processButton.addEventListener('click', function(event) {
            if (inputWindow.innerHTML.trim().length === 0 || inputWindow.innerHTML === placeHolder) {
                return;
            }

            let html = '<div style="font-size:' + self.defaultFontSize + 'vw" data-base-font-size="' + self.defaultFontSize + 'vw">'
                + inputWindow.innerHTML + '</div>';
            //let frag = document.createRange().createContextualFragment(html);
            let frag = domParser.parseFromString(html, 'text/html').body.firstChild;

            PqPasteHelper.convertFontSizeFromPxToVw(frag);

            self.curScaleFactorPointer = 0;
            inputWindow.setAttribute('contenteditable', false);

            self.replaceOutputContent(frag);

            processButton.style.display = 'none';
            resetButton.style.display = 'block';
        });

        resetButton.addEventListener('click', function(event) {
            // location.reload(true);
            // temp fix to make sure pq popup close event is triggered
            pcuesExtensionInterface.closePopup();

            inputWindow.innerHTML = placeHolder;
            inputWindow.setAttribute('contenteditable', true);

            self.curScaleFactorPointer = 0;

            resetButton.style.display = 'none';
            processButton.style.display = 'block';

            inputWindow.innerHTML = placeHolder;
            contentManager.disablePrintPdfButtons();

            // inputWindow.focus();
        });

        inputWindow.addEventListener('paste', function(event) {
            if (inputWindow.getAttribute('contenteditable') === 'false') {
                return;
            }

            // set time out is used to ensure content has already been pasted before being processed
            setTimeout(function() {
                let pastedText = sanitizeHtml(inputWindow.innerHTML, {
                    allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img', 'h1', 'h2', 'span', 'p', 'b', 'i' ]),
                    allowedAttributes: {
                        '*': ["style"],
                        'img': ['src'],
                        'span': ['class'],
                        'p': ['class'],
                    },
                    allowedStyles: {
                        '*': {
                            // Match HEX and RGB
                            'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/, /^[A-Za-z]+$/],
                            'text-align': [/^left$/, /^right$/, /^center$/],
                            // Match any number with px, em, %, x-small, xx-small, small, medium, large, x-large, xx-large
                            'font-size': [/^\d+(.\d+)?(px|em|pt|%)$/i, /^(x{1,2}-)?(small|large)$/i, /^medium$/i],
                            'margin-bottom': [/^\d+(.\d+)?(px|em|pt|%)$/i],
                            'margin-top': [/^\d+(.\d+)?(px|em|pt|%)$/i],
                            'line-height': [/^\d+(.\d+)?(px|em|pt|%)$/i]
                        }
                    }
                });
                console.log(pastedText);
                // let pastedText = inputWindow.innerHTML;

                //let frag = document.createRange().createContextualFragment(pastedText);
                let frag = domParser.parseFromString('<div>'+pastedText+'</div>', 'text/html').body.firstChild;

                /*if (frag.children.length <= 0) {
                    let container = document.createElement('div');
                    container.appendChild(frag.firstChild);
                    frag = container;
                } */

                // let delta = PqPasteHelper.computeDeltaBaseFontSize(frag);
                let delta = null;

                console.log('Delta: ' + delta);

                PqPasteHelper.convertFontSizeFromPxToVw(frag, delta);
                msDetected = false;

                self.curScaleFactorPointer = 0;

                inputWindow.setAttribute('contenteditable', false);
                self.replaceOutputContent(frag);

                processButton.style.display = 'none';
                resetButton.style.display = 'block';
            }, 0);

            // let pastedText = null;
            // if (event.clipboardData && event.clipboardData.getData) {
            //     pastedText = event.clipboardData.getData('text/html');
            //
            //     if (pastedText.length === 0) {
            //         console.log('Nothing from clipboard');
            //         pastedText = event.clipboardData.getData('text');
            //     } else {
            //         pastedText = sanitizeHtml(pastedText, {
            //             allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img', 'h1', 'h2', 'span' ])
            //         });
            //     }
            // }
            //
            // let frag = document.createRange().createContextualFragment(pastedText);
            //
            // // let delta = PqPasteHelper.computeDeltaBaseFontSize(frag);
            // let delta = null;
            //
            // console.log('Delta: ' + delta);
            //
            // PqPasteHelper.convertFontSizeFromPxToVw(frag, delta);
            //
            // self.curScaleFactorPointer = 0;
            //
            // inputWindow.setAttribute('contenteditable', false);
            // self.replaceOutputContent(frag);
            //
            // processButton.style.display = 'none';
            // resetButton.style.display = 'block';
            //
            // return false;
        });

        document.getElementById('pq_paste_plus_size').addEventListener('click', function(event) {
            // limit reached
            if (self.curScaleFactorPointer === self.textScaleFactors.length - 1) {
                return;
            }

            self.curScaleFactorPointer++;

            //let frag = document.createRange().createContextualFragment(inputWindow.innerHTML);
            let frag = domParser.parseFromString(inputWindow.innerHTML, 'text/html').body.firstChild;

            PqPasteHelper.adjustFontSizeBy(frag, self.textScaleFactors[self.curScaleFactorPointer]);

            self.replaceOutputContent(frag);
        });

        document.getElementById('pq_paste_minus_size').addEventListener('click', function(event) {
            // limit reached
            if (self.curScaleFactorPointer === 0) {
                return;
            }

            self.curScaleFactorPointer--;

            //let frag = document.createRange().createContextualFragment(inputWindow.innerHTML);
            let frag = domParser.parseFromString(inputWindow.innerHTML, 'text/html').body.firstChild;

            PqPasteHelper.adjustFontSizeBy(frag, self.textScaleFactors[self.curScaleFactorPointer]);

            self.replaceOutputContent(frag);
        });
    }

    /**
     * change the content of the output window with a node
     * @param node
     */
    replaceOutputContent(node) {
        document.getElementById('pq_paste_text_slot').innerHTML = '';
        document.getElementById('pq_paste_text_slot').appendChild(node);
    }
}

if (typeof document !== 'undefined') {
    document.addEventListener('DOMContentLoaded', function() {
        sanitizeHtml.defaults.allowedAttributes['img'] = [ 'src' ];

        let pqPasteController = new PqPasteController();
        pqPasteController.run();
    });
}